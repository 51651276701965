<template>
  <div class="influencer">
    <div class="_title">
      For Influencers
    </div>
    <p>
      We provide unique opportunities to engage with the brands they love while
      monetizing their efforts. We take care of the details so that Haima
      Media's talented members can focus on their craft.
    </p>
    <div class="_title">
      Brand Partners
    </div>
    <img src="../assets/image/banner10.png" alt="" />
  </div>
</template>

<style lang="less" scoped>
.influencer {
  margin: 0 auto;
  width: 5.65rem;
  ._title {
    text-align: center;
    margin: 0.45rem 0;
    font-size: 0.4rem;
    font-family: Arial;
    font-weight: normal;
    color: #000000;
  }
  img {
    width: 5.65rem;
  }
  p {
    font-size: 0.25rem;
    font-family: Arial;
    font-weight: 400;
    color: #000000;
    line-height: 0.4rem;
    margin-bottom: 0.5rem;
  }
}
</style>
